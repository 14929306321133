import React from 'react';
import Logo_one from '../images/BLone.jpg';
import Logo_two from '../images/BLtwo.jpg';
import Logo_three from '../images/BLthree.jpg';
import Logo_four from '../images/BLfour.jpg';
import DLogo_one from '../images/PEAR_lightmode.png'; 
import DLogo_two from '../images/RealNifty_lightmode.png'; 
import DLogo_three from '../images/QUICKSWAP_lightmode.png'; 
import DLogo_four from '../images/SHIELD_lightmode.png'; 

function Brands({ enableDarkMode }) {
  const logos = [
    {
      href: 'https://www.realnifty.xyz/',
      image: enableDarkMode ? Logo_one :   DLogo_two,
      alt: 'Brand Logo 1',
    },
    {
      href: 'https://www.pear.garden/',
      image: enableDarkMode ? Logo_two :  DLogo_one,
      alt: 'Brand Logo 2',
    },
    {
      href: 'https://quickswap.exchange/',
      image: enableDarkMode ? Logo_three :  DLogo_three,
      alt: 'Brand Logo 3',
    },
    {
      href: 'https://www.getshield.xyz/',
      image: enableDarkMode ? Logo_four :  DLogo_four,
      alt: 'Brand Logo 4',
    },
  ];

  return (
    <div className='brands-container tw-flex tw-flex-col tw-mt-14'>
      <div className='brands-list-header tw-font-bold tw-text-center tw-mb-8'>
        Trusted by Leading Web3 Brands
      </div>
      <div className='brands-logo-wrapper tw-flex tw-flex-wrap tw-justify-center'>
        {logos.map((logo, index) => (
          <a href={logo.href} target='_blank' rel='noopener noreferrer' key={index} className='brands-logo-container cursor-pointer'>
            <img
              src={logo.image}
              className='brands-logo'
              alt={logo.alt}
            />
          </a>
        ))}
      </div>
    </div>
  );
}

export default Brands;
